import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/style.css";
import logo from "../../assets/image/logo.png";
import facebook from "../../assets/image/facebook.png";
import google from "../../assets/image/google.png";
import { LoginSchema } from "../../schema";
import { useFormik } from "formik";
import { postData } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions";
import eyeshowicon from "../../assets/image/eye-show.svg";
import eyehideicon from "../../assets/image/eye-hide1.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setUser } from "../../reducers/userReducer";
import authService from "../../services/auth";
import { gettoken } from "../../services/posts";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [remember, setRemember] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  const initialValues = {
    email: "",
    password: "",
  };
  const { values, handleBlur, handleChange, errors, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        const res = await postData(values, "signInWithEmail");
        if (res.data.status) {
          const { data, Usertoken } = res.data;

          // Check if data and Usertoken are not null or undefined
          if (data && Usertoken) {
            dispatch(login({ user: data && data, token: Usertoken }));
            const userData = { user: data, token: Usertoken };

            const jsonData = JSON.stringify(userData);

            // Store the JSON string in local storage with a key, for example, 'userData'
            const storage = remember ? localStorage : sessionStorage;
            storage.setItem("userData", jsonData);
            authService.setToken(Usertoken);
            localStorage.setItem("token", Usertoken);
            gettoken(Usertoken);

            navigate("/Home");
          } else {
            // Handle case where data or Usertoken is null or undefined
            toast("User data is missing");
          }
        } else {
          toast(res.data.message);
        }
      } catch (error) {
        console.error("Error occurred while signing in:", error);
        toast("An error occurred while signing in");
      }
    },
  });
  const mystate = useSelector((state) => state.authActions);

  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col md="5" className="p-0">
              <div className="loginlogo_div">
                <img className="loginlogoimg" src={logo} alt="" />
              </div>
            </Col>
            <Col className="loginfield_bg" md="7">
              <div className="loginmain_div">
                <div className="headlogin_div">
                  <h3 className="login_head">Welcome To WelFore Health</h3>
                  <p className="card-text">
                    Effortlessly manage your lifestyle and discover new ways to
                    stay healthy!
                  </p>
                </div>
                <Form onSubmit={handleSubmit}>
                  <FormGroup className="form-group">
                    <Label className="form-label">Email Address</Label>
                    <Input
                      name="email"
                      placeholder="Email"
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <p className="from-error">{errors.email}</p>
                  <FormGroup className="form-group position-relative ">
                    <Label className="form-label">Password</Label>
                    <Input
                      name="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      type={showPassword2 ? "text" : "password"}
                    />
                    <div
                      className="iconright"
                      onClick={togglePasswordVisibility2}
                    >
                      {showPassword2 ? (
                        <img src={eyeshowicon} alt="" />
                      ) : (
                        <img src={eyehideicon} alt="" />
                      )}
                    </div>
                  </FormGroup>
                  <p className="from-error">{errors.password}</p>
                  <div className="d-flex justify-content-between">
                    <div className="form-check mb-1">
                      <Input
                        onChange={(e) => setRemember(e.target.value)}
                        checked={remember}
                        type="checkbox"
                        id="remember-me"
                      />
                      <Label className="form-check-label" for="remember-me">
                        Remember Me
                      </Label>
                    </div>
                    <div className="forgotpassword_link">
                      <Link to="/ForgotPassword">Forgot Password?</Link>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    {/* <Button className="cancel_btn mt-4 me-2" type="submit">
                      Cancel
                    </Button> */}

                    <Button className="login_btn" type="submit" color="primary">
                      Login
                    </Button>
                  </div>
                  <div className="divider my-4">
                    <div className="divider-text">or</div>
                  </div>
                  <div className="sociallogin_div">
                    <Link className="social_btn">
                      <img src={facebook} alt="" />
                    </Link>
                    <Link className="social_btn">
                      <img src={google} alt="" />
                    </Link>
                  </div>
                  <p className="bottombtn_account text-center mt-3">
                    <span className="me-1">Don't have an account? </span>
                    <Link to="/Signup">
                      <span>Create Account</span>
                    </Link>
                  </p>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </>
  );
};

export default Login;
