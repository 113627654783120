import axios from "axios";

const client = axios.create({
   baseURL: "https://admin.askportia.ai/api/",

});



const postData = async (values, url, token = null, contentType = "json") => {
  try {
    let headers = {
      "Content-Type":
        contentType === "json" ? "application/json" : "multipart/form-data",
    };
    // Include Authorization header if token is provided
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    let dataToSend = values;

    // If content type is form data, convert values to FormData
    if (contentType === "form-data") {
      dataToSend = new FormData();
      for (const key in values) {
        dataToSend.append(key, values[key]);
      }
    }

    const response = await client.post(url, dataToSend, {
      headers,
    });

    return response;
  } catch (error) {
    return error;
  }
};

const getData = async (url, token = null) => {
  try {
    const headers = {
      "Content-Type": "application/json", // Adjust content type as needed
    };

    // Include Authorization header if token is provided
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const response = await client.get(url, { headers });
    return response;
  } catch (error) {
    return error;
  }
};

export { client, postData, getData };
