import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import img1 from "../../assets/image/img/img1.jpg";
import img2 from "../../assets/image/img/img2.jpg";
import img3 from "../../assets/image/img/img3.jpg";
import img4 from "../../assets/image/img/img4.jpg";
import img5 from "../../assets/image/img/img5.jpg";
import img6 from "../../assets/image/img/img6.jpg";
import img7 from "../../assets/image/img/img7.jpg";
import img8 from "../../assets/image/img/img8.jpg";
import NextSevenDates from "../Helper/NextSevenDates";
import { getData } from "../../api/api";

const CircularProgressBar = ({
  radius,
  strokeWidth,
  progress,
  strokeColor,
}) => {
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg height={radius * 2} width={radius * 2}>
      <circle
        stroke="#F2F9FB"
        fill="transparent"
        strokeWidth={strokeWidth}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        stroke={strokeColor || "#87D234"} // Use the provided strokeColor or 'blue' as the default
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference + " " + circumference}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dy=".3em"
        fontSize="16"
        fill="#343434"
      >
        {progress}%
      </text>
    </svg>
  );
};

const NutritionPlanner = (args) => {
  const [mainData, setMainData] = useState({});
  const [StartWeight, setStartWeight] = useState(null);
  const [CurrentWeight, setCurrentWeight] = useState(null);
  const [EatenInWeek, setColorsEatenInWeek] = useState(null);
  const [EatenToday, setColorsEatenToday] = useState(null);
  const [Goal, setGoal] = useState(null);
  const [countArray, setStreakCount] = useState([]);
  const [StreakArray, setStreakArray] = useState([]);
  const [Streak, setStreak] = useState(null);
  useEffect(() => {
    const fetchtoken = async () => {
      const currentDate = new Date().toISOString().split("T")[0];
      const storedData =
        (await localStorage.getItem("userData")) ||
        sessionStorage.getItem("userData");
      // Parse the JSON string back to an object
      const parsedData = JSON.parse(storedData);

      if (parsedData.user._id) {
        const res = await getData(
          `userDashboard?userId=${parsedData.user._id}`
        );
        if (res.data.data) {
          setStartWeight(res.data.data.startWeight);
          setCurrentWeight(res.data.data.currentWeight);
          setColorsEatenInWeek(res.data.data.colorsEatenInWeek);
          setColorsEatenToday(res.data.data.colorsEatenToday);
          setGoal(res.data.data.goal);
          setStreak(res.data.data.streak);

          setStreakArray(res.data.data.activityArray);
        }

        await getData(
          `adminReadSingleUserDiet?userId=${parsedData.user._id}&date=${currentDate}`,
          parsedData.token
        )
          .then((res) => {
            console.log(res);
            const groupedByType = res.data.data.reduce((acc, item) => {
              if (!acc[item.type]) {
                acc[item.type] = [];
              }
              const { type, ...rest } = item;
              acc[item.type].push(rest);
              return acc;
            }, {});
            setMainData(groupedByType);
          })
          .catch((err) => {
            console.log("Nutrition planner api error");
          });
      } else {
        console.log("Nutrition planner api error 2");
      }
    };
    fetchtoken();
  }, []);

  return (
    console.log(mainData),
    (
      <>
        <div className="welcome_Div">
          <Row>
            <Col lg="8">
              <h1>Daily Nutrition Journal</h1>
              <p>
                Unveil your daily diet delights. Open 'My Nutrition Diary' to
                discover your tailored meal plan and your culinary colors.
              </p>
            </Col>
          </Row>
        </div>
        <Row>
          <Col lg="4" className="mb-3">
            <div className="boxdiv">
              <h2>Colors of the Rainbow Today</h2>

              <p>{EatenToday ? EatenToday : "0"} </p>
              {/* <div className="text-end">
                <CircularProgressBar
                  radius={50}
                  strokeWidth={4}
                  progress={EatenToday ? (EatenToday / 20) * 100 : ""}
                  strokeColor={"#87D234"}
                />
              </div> */}
            </div>
          </Col>
          <Col lg="4" className="mb-3">
            <div className="boxdiv">
              <h2>Colors of the Rainbow This Week</h2>
              <p>{EatenInWeek ? EatenInWeek : "0"} </p>
              {/* <div className="text-end">
                <CircularProgressBar
                  radius={50}
                  strokeWidth={4}
                  progress={EatenInWeek ? (EatenInWeek / 20) * 100 : ""}
                  strokeColor={"#ECA637"}
                />
              </div> */}
            </div>
          </Col>
          <Col lg="4" className="mb-3">
            <div className="boxdiv">
              <h2>Streaks</h2>
              <p>{Streak} days - Good job!</p>
              <small>Manage Nudges</small>
              <div className="currentweek_div mt-3">
                <ul className="currentweek_ul">
                  {Object.entries(StreakArray).map(([day, value]) => (
                    <li key={day}>
                      {day.charAt(0).toUpperCase()}
                      <span
                        className={
                          value.toString() == "true"
                            ? "badge badge_success"
                            : "badge badge_pending"
                        }
                      ></span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Col>
          <Col lg="12" className=" mb-3">
            <div className="dayweek">
              <NextSevenDates></NextSevenDates>
            </div>
          </Col>
        </Row>
        <div className="mb-4">
          <Link to="/AddDiaryEntry">
            <Button color="primary" className="px-3">
              Add Today’s Diary
            </Button>
          </Link>
        </div>
        <div className="mb-3">
          {Object.keys(mainData).length > 0 &&
            Object.keys(mainData).map((key, idx) => (
              <>
                <h2>{key}</h2>
                <div className="">
                  <div className="table-responsive">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td width="10%">Image</td>
                          <td width="25%">dietName</td>
                          <td width="20%">quantity</td>
                          <td width="15%">color</td>
                          <td width="15%">category</td>
                          <td width="15%">{/* <Link>View Details</Link> */}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <p>No Diet Plan sheuled Yet for you .</p> */}
                <div className="table-responsive">
                  <table class="table tablecustom">
                    <tbody>
                      {mainData[key].map((item, index) => (
                        <tr key={index}>
                          <td width="10%">
                            <img
                              src={`https://askportia.s3.amazonaws.com/${item.dietImage}`}
                              alt={`Image for ${item.name}`}
                            />
                          </td>
                          <td width="25%">{item.dietName}</td>
                          <td width="20%">{item.quantity}</td>
                          <td width="15%">{item.color}</td>
                          <td width="15%">{item.category}</td>
                          <td width="15%">{/* <Link>View Details</Link> */}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ))}
        </div>
      </>
    )
  );
};

export default NutritionPlanner;
