import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Progress } from "reactstrap";
import { Link } from "react-router-dom";
import source1 from "../../assets/image/dashboard.svg";
import source2 from "../../assets/image/NutritionPlanner.svg";
import source3 from "../../assets/image/NutritionBootcamp.svg";
import source4 from "../../assets/image/Library.svg";
import source5 from "../../assets/image/Community.svg";
import source6 from "../../assets/image/LiveSupport.svg";
import source7 from "../../assets/image/AccountSettings.svg";
import source8 from "../../assets/image/MealPlans.svg";
import banner_img1 from "../../assets/image/banner_img1.svg";
import { getData } from "../../api/api";
const Home = (args) => {
  const [StartWeight, setStartWeight] = useState(null);
  const [CurrentWeight, setCurrentWeight] = useState(null);
  const [EatenInWeek, setColorsEatenInWeek] = useState(null);
  const [EatenToday, setColorsEatenToday] = useState(null);
  const [Goal, setGoal] = useState(null);
  const [Streak, setStreak] = useState(null);
  const [StreakArray, setStreakArray] = useState([]);
  const [userName, setUserName] = useState("Loding....");

  const navigate = useNavigate();
  useEffect(() => {
    const fetchtoken = async () => {
      const storedData =
        localStorage.getItem("userData") || sessionStorage.getItem("userData");
      // Parse the JSON string back to an object
      const parsedData = JSON.parse(storedData);
      setUserName(parsedData.user.firstName);
      if (parsedData.token && parsedData.user._id) {
        fetchData(parsedData.token, parsedData.user._id);
      } else {
        navigate("/");
      }
    };
    fetchtoken();
  }, []);

  const fetchData = async (token, user_id) => {
    try {
      const res = await getData(`userDashboard?userId=${user_id}`, token);
      if (res.data.data) {
        setStartWeight(res.data.data.startWeight);
        setCurrentWeight(res.data.data.currentWeight);
        setColorsEatenInWeek(res.data.data.colorsEatenInWeek);
        setColorsEatenToday(res.data.data.colorsEatenToday);
        setGoal(res.data.data.goal);
        setStreak(res.data.data.streak);
        setStreakArray(res.data.data.activityArray);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="8">
            <h1>Welcome back, {userName ? userName : ""}</h1>
            <p>
              Effortlessly manage your lifestyle and discover new ways to stay
              healthy!
            </p>
          </Col>
        </Row>
      </div>
      <Row className="mb-5">
        <Col lg="8">
          <div className="mealbanner_div">
            <Row>
              <Col sm="8">
                <div className="mealbanner_content">
                  <h2 className="title">
                    All the best flavors and ingredients without sacrificing the
                    foods you love! We don’t count calories here.
                  </h2>
                  <p className="subtitle">
                    Our meal plans are expertly and deliciously crafted to help
                    you easily eat healthier resulting in your clothes fitting
                    better, you feeling happier!
                  </p>
                  <Link to="/Mealplan" className="mealbanner_btn">
                    View Meal Plans
                  </Link>
                </div>
              </Col>
              <Col sm="4" className="mealbanner_img">
                <img className="banner_img" src={banner_img1} />
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg="4">
          <div className="mealgoal_div mb-3">
            <Row className="mb-2">
              <Col xs="4" className="border-end">
                <h5>Start</h5>
                <h4>0 lbs</h4>
              </Col>
              <Col xs="4" className="border-end">
                <h5>Current</h5>
                <h4>0 lbs</h4>
              </Col>
              <Col xs="4">
                <h5>Goal</h5>
                <h4>0 lbs</h4>
              </Col>
            </Row>
            <Progress value={75} />
          </div>
          <div className="mealgoal_div mb-3">
            <Row>
              <Col xs="5">
                <h5>Current Streak</h5>
                <h4>{Streak ? Streak : ""} days</h4>
              </Col>
              <Col xs="7" className="align-self-end">
                <div className="currentweek_div">
                  <ul className="currentweek_ul">
                    {Object.entries(StreakArray).map(([day, value]) => (
                      <li key={day}>
                        {day.charAt(0).toUpperCase()}
                        <span
                          className={
                            value.toString() == "true"
                              ? "badge badge_success"
                              : "badge badge_pending"
                          }
                        ></span>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          <div className="mealgoal_div">
            <Row>
              <Col xs="6" className="border-end">
                <h5>Colors Eaten Today</h5>
                <h4>{EatenToday ? EatenToday : "0"}</h4>
              </Col>
              <Col xs="6">
                <h5>Colors Eaten This Week</h5>
                <h4>{EatenInWeek ? EatenInWeek : "0"}</h4>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <h3 className="homemeal_head">What do you want to do today?</h3>
      <Row className="match-height">
        <Col lg="3" sm="6" className="colp_10">
          <div className="card mealplan_box">
            <Link to="/Dashboard">
              <div className="card-body">
                <div className="d-block">
                  <div className="mealplan_img">
                    <img
                      className="borderad-none rounded-0"
                      src={source1}
                      height={50}
                    />
                  </div>
                  <h3>My Dashboard</h3>
                  <p className="card-text">
                    Every day is a new step towards your health. Open your
                    dashboard to celebrate your daily health progress.
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </Col>
        <Col lg="3" sm="6" className="colp_10">
          <div className="card mealplan_box">
            <Link to="/NutritionPlanner">
              <div className="card-body">
                <div className="d-block">
                  <div className="mealplan_img">
                    <img
                      className="borderad-none rounded-0"
                      src={source2}
                      height={50}
                    />
                  </div>
                  <h3>My Daily Nutrition Journal</h3>
                  <p className="card-text">
                    Unveil your daily diet delights. Open 'My Nutrition Diary'
                    to discover your tailored meal plan and your culinary
                    colors.
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </Col>
        <Col lg="3" sm="6" className="colp_10">
          <div className="card mealplan_box">
            <Link to="/Mealplan">
              <div className="card-body">
                <div className="d-block">
                  <div className="mealplan_img">
                    <img
                      className="borderad-none rounded-0"
                      src={source8}
                      height={50}
                    />
                  </div>
                  <h3>My Meal Plans</h3>
                  <p className="card-text">
                    Explore a delicious world of health-focused flavors! Choose
                    from Bariatric, Diabetes, Heart, Vegetarian, and Vegan
                    Health plans for a meal plan tailored to help achieve and
                    celebrate your wellness goals.
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </Col>
        <Col lg="3" sm="6" className="colp_10">
          <div className="card mealplan_box">
            <Link to="/NutritionBootcamp">
              <div className="card-body">
                <div className="d-block">
                  <div className="mealplan_img">
                    <img
                      className="borderad-none rounded-0"
                      src={source3}
                      height={50}
                    />
                  </div>
                  <h3>My Nutrition Academy</h3>
                  <p className="card-text">
                    Celebrate your culinary achievement with each course! 'My
                    Nutrition Academy' is your guide through 12 weeks of
                    mastering and practicing healthy dietary principles
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </Col>
        <Col lg="3" sm="6" className="colp_10">
          <div className="card mealplan_box">
            <Link to="/community">
              <div className="card-body">
                <div className="d-block">
                  <div className="mealplan_img">
                    <img
                      className="borderad-none rounded-0"
                      src={source5}
                      height={50}
                    />
                  </div>
                  <h3>My Community</h3>
                  <p className="card-text">
                    Together, we grow stronger with every step. Join to
                    experience unity and encouragement on your path to better
                    health.
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </Col>
        <Col lg="3" sm="6" className="colp_10">
          <div className="card mealplan_box">
            <Link to="/Videos">
              <div className="card-body">
                <div className="d-block">
                  <div className="mealplan_img">
                    <img
                      className="borderad-none rounded-0"
                      src={source4}
                      height={50}
                    />
                  </div>
                  <h3>My Library</h3>
                  <p className="card-text">
                    Embark on a culinary adventure. 'My Library' will excite you
                    with a collection of cooking videos and recipes that make
                    nutrition easy, fun and fascinating.
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </Col>
        <Col lg="3" sm="6" className="colp_10">
          <div className="card mealplan_box">
            <Link to="/ContactUs">
              <div className="card-body">
                <div className="d-block">
                  <div className="mealplan_img">
                    <img
                      className="borderad-none rounded-0"
                      src={source6}
                      height={50}
                    />
                  </div>
                  <h3>Live Support</h3>
                  <p className="card-text">
                    To navigate our site with ease, and clarity, open 'My Live
                    Support' for all your queries about our site, from FAQs to
                    terms and conditions.
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </Col>
        <Col lg="3" sm="6" className="colp_10">
          <div className="card mealplan_box">
            <Link to="/ProfileSetting">
              <div className="card-body">
                <div className="d-block">
                  <div className="mealplan_img">
                    <img
                      className="borderad-none rounded-0"
                      src={source7}
                      height={50}
                    />
                  </div>
                  <h3>Account Settings</h3>
                  <p className="card-text">
                    Tailor your experience to your taste. Open 'My Account
                    Settings' to customize your profile, appearance, and more
                    for a truly personal touch.
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Home;
