import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Login from "../pages/authentication/Login";

const ProtectedRoute = () => {
  // Check for token
  const storedData =
    localStorage.getItem("userData") || sessionStorage.getItem("userData");
  console.log("-------running" + storedData);
  // If there's no token, redirect to the login page, else render children
  return storedData ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;
