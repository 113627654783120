import React, { useState, useEffect } from "react";
import { Row, Col, Button, FormGroup, Form, Input, Label } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ChevronLeft } from "react-feather";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import glass from "../../../assets/image/img/glass.svg";
import smile from "../../../assets/image/img/smile.svg";
import upload from "../../../assets/image/img/upload.svg";
import { useFormik } from "formik";
import { DiarySchema } from "../../../schema";
import { getData, postData } from "../../../api/api";
import store from "../../../store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ViewDiary = (args) => {
  const navigate = useNavigate();
  const [sliderValue, setSliderValue] = useState(6);
  const [sliderValue2, setSliderValue2] = useState(4);
  const [token, setToken] = useState(null);
  const { date } = useParams();
  const [diary, setDiary] = useState({});

  useEffect(() => {
    const storedData =
      localStorage.getItem("userData") || sessionStorage.getItem("userData");
    const parsedData = JSON.parse(storedData);
    console.log(parsedData.user._id);
    if (parsedData.user._id) {
      setToken(parsedData.user._id);

      const fetchData = async () => {
        try {
          const res = await getData(
            `adminFetchUserDiary?userId=${parsedData.user._id}&&date=${date}`
          );
          setDiary(res.data.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    } else {
      navigate("/");
    }
  }, []);

  return (
    console.log(diary),
    (
      <>
        <div className="welcome_Div">
          <Row>
            <Col lg="8" className="breadcrumbtop">
              <Link to="/NutritionPlanner">
                <ChevronLeft />
              </Link>
              <div>
                <h1>View Diary Entry</h1>
              </div>
            </Col>
          </Row>
        </div>
        <div className="adddairyentry card_box">
          <Row className="mt-4">
            <Col lg="12">
              {diary && Object.keys(diary).length > 0 ? (
                <>
                  <div className="question_card">
                    <h6 className="question_head">
                      <b>
                        How much water or low sweet beverages did you drink
                        today?
                      </b>
                    </h6>
                    <p className="answer_text">
                      {diary.waterIntake ? diary.waterIntake : "N/A"}
                    </p>
                  </div>
                  <div className="question_card">
                    <h6 className="question_head">
                      <b>
                        How many colors of the rainbow have you eaten today?
                      </b>
                    </h6>
                    <p className="answer_text">
                      {diary.colorsEaten ? diary.colorsEaten : "N/A"}
                    </p>
                  </div>
                  <div className="question_card">
                    <h6 className="question_head">
                      <b>How many grams of fiber have you eaten today?</b>
                    </h6>
                    <p className="answer_text">
                      {diary.fiberEaten === "No Answer"
                        ? diary.fiberEaten
                        : diary.fiberEaten + " " + "g"}{" "}
                    </p>
                  </div>
                  {/* <div className="question_card">
                    <h6 className="question_head">
                      <b>What’s your body weight?</b>
                    </h6>
                    <p className="answer_text">
                      {diary.weightInLbs === "No Answer"
                        ? diary.weightInLbs
                        : diary.weightInLbs + " " + "lbs"}
                    </p>
                  </div> */}
                  {/* <div className="question_card">
                    <h6 className="question_head">
                      <b>What’s your blood pressure?</b>
                    </h6>
                    <p className="answer_text">
                      {diary.bloodPressure ? diary.bloodPressure : "N/A"}
                    </p>
                  </div> */}
                  <div className="question_card">
                    <h6 className="question_head">
                      <b>
                        How many minutes of intentional physical movement have
                        you enjoyed today?
                      </b>
                    </h6>
                    <p className="answer_text">
                      {diary.physicalmovement ? diary.physicalmovement : "N/A"}
                    </p>
                  </div>
                  <div className="question_card">
                    <h6 className="question_head">
                      <b>How do you feel today?</b>
                    </h6>

                    <p className="answer_text">
                      {diary.Mindset ? diary.Mindset : "N/A"}
                    </p>
                  </div>
                  {diary.foodImages.length > 0 && (
                    <div className="question_card">
                      <h6 className="question_head">
                        <b>Food Image</b>
                      </h6>

                      <img
                        src={
                          "https://askportia.s3.amazonaws.com/" +
                          diary.foodImages
                        }
                        style={{ width: "100px", height: "100px" }}
                        alt="Food Image"
                      />
                    </div>
                  )}
                </>
              ) : (
                <p>No diary entries available.</p>
              )}
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </>
    )
  );
};

export default ViewDiary;
