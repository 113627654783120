import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const DateLink = ({ date, highlight }) => {
  const options = { weekday: "long", day: "numeric" };
  const day = date.toLocaleDateString("en-US", { weekday: "long" });
  const formattedDate = date.toLocaleDateString("en-US", { day: "numeric" });
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day2 = String(date.getDate()).padStart(2, "0");
  var fulldate = `${year}-${month}-${day2}`;

  return (
    <Link
      to={`/ViewDiary/${fulldate}`}
      className={`text-center ${highlight ? "highlight-date" : ""}`}
    >
      <h2>{formattedDate}</h2>
      <p>{day}</p>
    </Link>
  );
};

const NextSevenDates = () => {
  const currentDate = new Date();
  const nextSevenDates = [];
  const previousSevenDates = [];

  for (let i = 5; i >= 0; i--) {
    const nextDate = new Date();
    nextDate.setDate(currentDate.getDate() + i);
    nextSevenDates.push(nextDate);

    const previousDate = new Date();
    previousDate.setDate(currentDate.getDate() - i);
    previousSevenDates.push(previousDate);
  }

  return (
    <div>
      <Row>
        {previousSevenDates.map((date, index) => (
          <Col lg="2" xs="2" key={index}>
            <DateLink date={date} highlight={index === 5} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default NextSevenDates;
